import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { getMeDetails } from '../../actions/userActions';
import { USER_LOGIN_SUCCESS, USER_LOGIN_FAIL} from '../../constants/userConstants';

const NHSMailLogin = ({ onLoginSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [listening, setListening] = useState(false);
    const [loginWindow, setLoginWindow] = useState(null);
    const [loginUrl, setLoginUrl] = useState(null);
    const [isPopupBlocked, setIsPopupBlocked] = useState(false);
    const [debugUserInfo, setDebugUserInfo] = useState(null);
    const dispatch = useDispatch();

    function camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    const convertKeysToCamelCase = (item) => {
        if (Array.isArray(item)) {
            return item.map(convertKeysToCamelCase);
        } else if (item !== null && typeof item === 'object') {
            return Object.keys(item).reduce((acc, key) => {
                acc[camelize(key)] = convertKeysToCamelCase(item[key]);
                return acc;
            }, {});
        }
        return item;
    };

    const handleNHSMailLogin = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get('/api/v1/Auth/nhsmailauth');
            if (response.data && response.data.url) {
                setLoginUrl(response.data.url);
                const newWindow = window.open(response.data.url, "_blank");
                if (newWindow) {
                    setLoginWindow(newWindow);
                    setListening(true);
                    setIsPopupBlocked(false);
                } else {
                    setIsPopupBlocked(true);
                }
            } else {
                setError('Invalid response from server');
            }
        } catch (error) {
            setError('Error initiating NHSMail login. Please try again.');
            console.error('Error initiating NHSMail login:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleManualLogin = () => {
        const newWindow = window.open(loginUrl, "_blank");
        if (newWindow) {
            setLoginWindow(newWindow);
            setListening(true);
            setIsPopupBlocked(false);
        }
    };

    useEffect(() => {
        const handleMessage = async (event) => {
            if (event.data && event.data.Token) {
                const userInfo = convertKeysToCamelCase(event.data);
                setDebugUserInfo(userInfo);
                dispatch({
                    type: USER_LOGIN_SUCCESS,
                    payload: userInfo
                });


                localStorage.setItem("userInfo", JSON.stringify(userInfo));


                localStorage.setItem("2fauthenticated", JSON.stringify(true));

                if (loginWindow) loginWindow.close();

                try {
                    // Only redirect after getMeDetails has completed successfully
                    await dispatch(getMeDetails());
                    onLoginSuccess(JSON.stringify(userInfo));
                    setListening(false);

                } catch (error) {
                    let errorMessage = error.response && error.response.data.detail
                        ? error.response.data.detail
                        : error.message
                    // catch 401 error

                    dispatch({
                        type: USER_LOGIN_FAIL,
                        payload: errorMessage,
                    })
                    console.error('Error fetching user details:', error);
                    setError('Error fetching user details. Please try logging in again.');
                }
            }
        };

        const checkLoginStatus = () => {
            if (!listening) return;

            if (loginWindow && loginWindow.closed) {
                setListening(false);
                setError('Login window was closed. Please try again.');
            }
        };

        let interval;
        if (listening) {
            window.addEventListener('message', handleMessage);
            interval = setInterval(checkLoginStatus, 2000);
        }

        return () => {
            window.removeEventListener('message', handleMessage);
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [listening, onLoginSuccess, loginWindow, dispatch]);

    return (
        <>
            <button
                className="nhslogin-button nhslogin-button-logo"
                style={{ width: "100%" }}
                onClick={handleNHSMailLogin}
                disabled={isLoading || listening}
            >
                <div className="button-logo">
                    <svg className="nhsuk-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 16" height="14" width="60">
                        <path className="nhsuk-logo__background" fill="#fff" d="M0 0h40v16H0z"></path>
                        <path className="nhsuk-logo__text" fill="#005eb8" d="M3.9 1.5h4.4l2.6 9h.1l1.8-9h3.3l-2.8 13H9l-2.7-9h-.1l-1.8 9H1.1M17.3 1.5h3.6l-1 4.9h4L25 1.5h3.5l-2.7 13h-3.5l1.1-5.6h-4.1l-1.2 5.6h-3.4M37.7 4.4c-.7-.3-1.6-.6-2.9-.6-1.4 0-2.5.2-2.5 1.3 0 1.8 5.1 1.2 5.1 5.1 0 3.6-3.3 4.5-6.4 4.5-1.3 0-2.9-.3-4-.7l.8-2.7c.7.4 2.1.7 3.2.7s2.8-.2 2.8-1.5c0-2.1-5.1-1.3-5.1-5 0-3.4 2.9-4.4 5.8-4.4 1.6 0 3.1.2 4 .6"></path>
                    </svg>
                    {isLoading ? 'Logging in...' : listening ? 'Waiting for login...' : 'Continue with NHSMail'}
                </div>
            </button>
            {isPopupBlocked && (
                <div className="nhsuk-warning-callout">
                    <h3 className="nhsuk-warning-callout__label">Pop-up Blocked</h3>
                    <p>It seems your browser blocked the pop-up. Please click the link below to open the login window:</p>
                    <button className="nhsuk-button" onClick={handleManualLogin}>
                        Open NHSMail Login
                    </button>
                </div>
            )}
            {error && <div className="nhsuk-error-message" role="alert">{error}</div>}
        </>
    );
};

export default NHSMailLogin;