import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Table, ActionLink, Tag, Input, Fieldset, BackLink, Card, Details, Button, InsetText, Textarea, ErrorSummary, Select, Checkboxes } from 'nhsuk-react-components';
import { listImplantInstances, createImplantInstance, updateImplantInstance, deleteImplantInstance, getImplantInstanceDetails } from '../../actions/implantInstanceActions';
// constants 
import { IMPLANT_INSTANCE_CREATE_RESET, IMPLANT_INSTANCE_UPDATE_RESET } from '../../constants/implantInstanceConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';
import ISODateInput from '../../components/date/ISODateInput.component';
import Modal from '../../components/modal/Modal.component';

// Import the fixed version of the statistics dashboard
import ImplantStatisticsDashboard from '../../components/implantInstanceList/ImplantIstanceStatisticsDashboard.component';

export default function ImplantInstanceCRUDScreen({ history }) {
    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();
    
    // State for modals
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);

    // reset the state of the implantInstanceCreate and implantInstanceUpdate
    useEffect(() => {
        dispatch({ type: IMPLANT_INSTANCE_CREATE_RESET });
        dispatch({ type: IMPLANT_INSTANCE_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    const [implantInstanceListFilter, setimplantInstanceListFilter] = useState({ query: '', page: 1, filter: 'serialNumber' });

    // get implantInstance list from redux store
    const implantInstanceList = useSelector((state) => state.implantInstanceList);
    const { loading, error, implantInstances, currentPage, totalPages, statistics } = implantInstanceList;

    // get implantInstance create from redux store
    const implantInstanceCreate = useSelector((state) => state.implantInstanceCreate);
    const { success: successCreate, error: implantInstanceCreateErrors } = implantInstanceCreate;

    // get implantInstance update from redux store
    const implantInstanceUpdate = useSelector((state) => state.implantInstanceUpdate);
    const { success: successUpdate, loading: implantInstanceUpdateProcessing } = implantInstanceUpdate;

    // get implantInstance delete from redux store
    const implantInstanceDelete = useSelector((state) => state.implantInstanceDelete);
    const { success: successDelete } = implantInstanceDelete;

    // get implantInstance details from redux store
    const implantInstanceDetails = useSelector((state) => state.implantInstanceDetails);
    const { implantInstance: implantInstanceDet, loading: implantInstanceDetailsLoading } = implantInstanceDetails;

    // newImplantInstance state
    const [newImplantInstance, setNewImplantInstance] = useState({});
    const [updatedImplantInstance, setUpdatedImplantInstance] = useState({});
    const [editMode, setEditMode] = useState(false);

    // listen to filter changes
    useEffect(() => {
        dispatch(listImplantInstances(implantInstanceListFilter));
    }, [dispatch, implantInstanceListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setimplantInstanceListFilter({ ...implantInstanceListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setimplantInstanceListFilter({ ...implantInstanceListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setimplantInstanceListFilter({ ...implantInstanceListFilter, filter: selectedFilter });
    };

    // get all implantInstances
    useEffect(() => {
        dispatch(listImplantInstances({}));
    }, [dispatch]);

    // listen to implantInstanceDet 
    useEffect(() => {
        if (implantInstanceDet) {
            // use spread operator to copy the implantInstanceDet object
            setUpdatedImplantInstance({ ...implantInstanceDet });
        }
    }, [implantInstanceDet]);

    // Listen to edit mode changes
    useEffect(() => {
        if (!editMode) {
            // empty the newImplantInstance state
            setNewImplantInstance({
                patientName: '',
                patientNHSNumber: '',
                isFirstImplant: false,
                interventionCategory: '',
                modelName: '',
                modelNumber: '',
                barcode: '',
                manufacturer: '',
                modelCategory: '',
                type: '',
                eventDate: new Date().toISOString(),
                expiryDate: new Date().toISOString(),
                serialNumber: '',
                status: '',
                eventType: '',
                eventDetails: '',
                practitionerDetails: '',
                notes: '',
                generatorImplantSite: '',
                maxSystemCapability: '',
                leadChamber: '',
                pacingSite: '',
                bradyProgrammedMode: '',
                bradyProgrammedLowerRate: 0,
                activeHighVoltageTherapy: false,
                tachyTherapyZoneRate: 0,
                patientReceivedAppropriateHVTherapy: false,
                patientReceivedInappropriateHVTherapy: false,
                abandonedHardware: false,
                mrConditional: false,
            });
        }
    }, [editMode]);

    // Listen for success actions to close modals
    useEffect(() => {
        if (successCreate) {
            setShowCreateModal(false);
        }
        if (successUpdate) {
            setShowEditModal(false);
        }
    }, [successCreate, successUpdate]);

    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this implant record?')) {
            dispatch(deleteImplantInstance(id)).then(() => {
                dispatch(listImplantInstances({}));
            });
        }
    };

    // handle edit
    const handleEditImplantInstance = (id) => {
        dispatch(getImplantInstanceDetails(id));
        setEditMode(true);
        setShowEditModal(true);
    };

    // handle create
    const handleCreateImplantInstance = () => {
        dispatch(createImplantInstance(newImplantInstance)).then(() => {
            // We'll close the modal in useEffect when successCreate is true
            if (!implantInstanceCreateErrors) {
                dispatch(listImplantInstances({}));
            }
        });
    };

    // handle update
    const handleUpdateImplantInstance = () => {
        dispatch(updateImplantInstance(updatedImplantInstance.id, updatedImplantInstance)).then(() => {
            // We'll close the modal in useEffect when successUpdate is true
            if (!implantInstanceUpdateProcessing) {
                dispatch(getImplantInstanceDetails(updatedImplantInstance.id));
                dispatch(listImplantInstances({}));
            }
        });
    };


    // Edit Form Modal
    const renderEditModal = () => (
        <Modal 
            show={showEditModal} 
            handleClose={() => setShowEditModal(false)}
            theme="light"
        >
            <h2 className="nhsuk-heading-l">Edit Implant Record</h2>
            
            {implantInstanceDetailsLoading ? (
                <AppSpinner />
            ) : (
                <>
                    <Checkboxes name="checkboxes">
                        <Checkboxes.Box
                            checked={updatedImplantInstance?.isFirstImplant}
                            onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, isFirstImplant: e.target.checked })}
                        >
                            First Implant?
                        </Checkboxes.Box>
                    </Checkboxes>

                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-one-half">
                            <ISODateInput
                                id="updateEventDateTime"
                                label="Implantation Date"
                                name="eventDateTime"
                                value={updatedImplantInstance?.eventDate}
                                onChange={(dt) => setUpdatedImplantInstance({ ...updatedImplantInstance, eventDate: dt })}
                                required
                            />

                            <Input
                                id="patientName"
                                label="Patient Name"
                                name="patientName"
                                type="text"
                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, patientName: e.target.value })}
                                value={updatedImplantInstance?.patientName}
                                required
                            />
                            
                            <Input
                                id="patientNHSNumber"
                                label="Patient NHS Number"
                                name="patientNHSNumber"
                                type="text"
                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, patientNHSNumber: e.target.value })}
                                value={updatedImplantInstance?.patientNHSNumber}
                                required
                            />
                            
                            <Select
                                id="interventionCategory"
                                label="Intervention Category"
                                name="interventionCategory"
                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, interventionCategory: e.target.value })}
                                value={updatedImplantInstance?.interventionCategory}
                                required
                            >
                                <Select.Option value="">Select an intervention category</Select.Option>
                                <Select.Option value="No intervention (FU only of pt implanted elsewhere)">No intervention (FU only of pt implanted elsewhere)</Select.Option>
                                <Select.Option value="First ever pacemaker/ICD system">First ever pacemaker/ICD system</Select.Option>
                                <Select.Option value="Generator change only">Generator change only</Select.Option>
                                <Select.Option value="New generator and lead(s) revision/replacement">New generator and lead(s) revision/replacement</Select.Option>
                                <Select.Option value="Upgrade - increased functionality (to DDD, CRT, or ICD)">Upgrade - increased functionality (to DDD, CRT, or ICD)</Select.Option>
                                <Select.Option value="Downgrade - decreased functionality">Downgrade - decreased functionality</Select.Option>
                                <Select.Option value="System explant">System explant</Select.Option>
                                <Select.Option value="Wound revision/generator resite/haematoma">Wound revision/generator resite/haematoma</Select.Option>
                                <Select.Option value="Lead intervention only">Lead intervention only</Select.Option>
                                <Select.Option value="Monitor procedure only">Monitor procedure only</Select.Option>
                                <Select.Option value="Other">Other</Select.Option>
                            </Select>

                            <Input
                                id="modelName"
                                label="Model Name"
                                name="modelName"
                                type="text"
                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, modelName: e.target.value })}
                                value={updatedImplantInstance?.modelName}
                                required
                            />
                            
                            <Input
                                id="modelNumber"
                                label="Model Number"
                                name="modelNumber"
                                type="text"
                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, modelNumber: e.target.value })}
                                value={updatedImplantInstance?.modelNumber}
                                required
                            />

                            <Input
                                id="manufacturer"
                                label="Manufacturer"
                                name="manufacturer"
                                type="text"
                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, manufacturer: e.target.value })}
                                value={updatedImplantInstance?.manufacturer}
                                required
                            />
                        </div>
                        
                        <div className="nhsuk-grid-column-one-half">
                            <Select
                                id="modelCategory"
                                label="Model Category"
                                name="modelCategory"
                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, modelCategory: e.target.value })}
                                value={updatedImplantInstance?.modelCategory}
                                required
                            >
                                <Select.Option value="">Select a model category</Select.Option>
                                <Select.Option value="Accessory">Accessory</Select.Option>
                                <Select.Option value="Generator">Generator</Select.Option>
                                <Select.Option value="Lead">Lead</Select.Option>
                                <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                            </Select>

                            <Select
                                id="modelType"
                                label="Model Type"
                                name="modelType"
                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, type: e.target.value })}
                                value={updatedImplantInstance?.type}
                                required
                            >
                                <Select.Option value="">Select a model type</Select.Option>
                                <Select.Option value="LV lead">LV lead</Select.Option>
                                <Select.Option value="Lead">Lead</Select.Option>
                                <Select.Option value="Defibrillation Lead">Defibrillation Lead</Select.Option>
                                <Select.Option value="Single Chamber Defibrillator">Single Chamber Defibrillator</Select.Option>
                                <Select.Option value="Dual Chamber Defibrillator">Dual Chamber Defibrillator</Select.Option>
                                <Select.Option value="Cardiac Resynchronisation Therapy Defibrillator">Cardiac Resynchronisation Therapy Defibrillator</Select.Option>
                                <Select.Option value="Single chamber pacemaker">Single chamber pacemaker</Select.Option>
                                <Select.Option value="Dual Chamber Pacemaker">Dual Chamber Pacemaker</Select.Option>
                                <Select.Option value="Cardiac Resynchronisation Therapy Pacemaker">Cardiac Resynchronisation Therapy Pacemaker</Select.Option>
                                <Select.Option value="Accessory">Accessory</Select.Option>
                                <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                <Select.Option value="Accessory - Lead Cap">Accessory - Lead Cap</Select.Option>
                                <Select.Option value="Accessory - Pin Plug">Accessory - Pin Plug</Select.Option>
                                <Select.Option value="Epicardial lead">Epicardial lead</Select.Option>
                                <Select.Option value="His Lead">His Lead</Select.Option>
                            </Select>
                            
                            <Select
                                id="status"
                                label="Status"
                                name="status"
                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, status: e.target.value })}
                                value={updatedImplantInstance?.status}
                                required
                            >
                                <Select.Option value="">Select a status</Select.Option>
                                <Select.Option value="Implanted, connected">Implanted, connected</Select.Option>
                                <Select.Option value="Explanted">Explanted</Select.Option>
                                <Select.Option value="Partial extraction">Partial extraction</Select.Option>
                                <Select.Option value="Complete extraction">Complete extraction</Select.Option>
                                <Select.Option value="Implanted, abandoned">Implanted, abandoned</Select.Option>
                            </Select>

                            <Input
                                id="barcode"
                                label="Barcode/GTIN"
                                name="barcode"
                                type="text"
                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, barcode: e.target.value })}
                                value={updatedImplantInstance?.barcode}
                                required
                            />
                            
                            <ISODateInput
                                id="expiryDate"
                                label="Expiry Date"
                                name="expiryDate"
                                value={updatedImplantInstance?.expiryDate}
                                onChange={(dt) => setUpdatedImplantInstance({ ...updatedImplantInstance, expiryDate: dt })}
                                required
                            />
                            
                            <Input
                                id="serialNumber"
                                label="Serial Number"
                                name="serialNumber"
                                type="text"
                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, serialNumber: e.target.value })}
                                value={updatedImplantInstance?.serialNumber}
                                required
                            />

                            <Checkboxes name="status-checkboxes">
                                <Checkboxes.Box
                                    checked={updatedImplantInstance?.mrConditional}
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, mrConditional: e.target.checked })}
                                >
                                    MR Conditional
                                </Checkboxes.Box>

                                <Checkboxes.Box
                                    checked={updatedImplantInstance?.abandonedHardware}
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, abandonedHardware: e.target.checked })}
                                >
                                    Abandoned Hardware
                                </Checkboxes.Box>
                            </Checkboxes>
                        </div>
                    </div>
                    
                    <Textarea
                        id="notes"
                        label="Notes"
                        name="notes"
                        onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, notes: e.target.value })}
                        value={updatedImplantInstance?.notes}
                    />
                    
                    <div className="nhsuk-u-margin-top-4">
                        <Button 
                            className="nhsuk-u-margin-right-3"
                            disabled={implantInstanceUpdateProcessing}
                            onClick={handleUpdateImplantInstance}
                        >
                            {implantInstanceUpdateProcessing ? 'Saving...' : 'Save Changes'}
                        </Button>
                        <Button secondary onClick={() => setShowEditModal(false)}>
                            Cancel
                        </Button>
                    </div>
                </>
            )}
            
            {successUpdate && (
                <Callout
                    label="Success"
                    header="Success!"
                    autoDismiss={true}
                    dismissTime={5000}
                    time={new Date().toLocaleTimeString()}
                    title="Success!"
                    message="The implant record was updated successfully."
                    mode="success"
                />
            )}
        </Modal>
    );

    // Create Form Modal
    const renderCreateModal = () => (
        <Modal 
            show={showCreateModal} 
            handleClose={() => setShowCreateModal(false)}
            theme="light"
        >
            <h2 className="nhsuk-heading-l">Create New Implant Record</h2>
            
            <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-one-half">
                    <Checkboxes name="checkboxes">
                        <Checkboxes.Box
                            checked={newImplantInstance?.isFirstImplant}
                            onChange={(e) => setNewImplantInstance({ ...newImplantInstance, isFirstImplant: e.target.checked })}
                        >
                            First Implant?
                        </Checkboxes.Box>
                    </Checkboxes>

                    <ISODateInput
                        id="eventDate"
                        label="Implantation Date"
                        name="eventDate"
                        value={newImplantInstance?.eventDate}
                        onChange={(dt) => setNewImplantInstance({ ...newImplantInstance, eventDate: dt })}
                        required
                    />

                    <Input
                        id="create-patientName"
                        label="Patient Name"
                        name="createPatientName"
                        type="text"
                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, patientName: e.target.value })}
                        value={newImplantInstance?.patientName}
                        required
                    />
                    
                    <Input
                        id="patientNHSNumber"
                        label="Patient NHS Number"
                        name="patientNHSNumber"
                        type="text"
                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, patientNHSNumber: e.target.value })}
                        value={newImplantInstance?.patientNHSNumber}
                        required
                    />
                    
                    <Select
                        id="interventionCategory"
                        label="Intervention Category"
                        name="interventionCategory"
                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, interventionCategory: e.target.value })}
                        value={newImplantInstance?.interventionCategory}
                        required
                    >
                        <Select.Option value="">Select an intervention category</Select.Option>
                        <Select.Option value="No intervention (FU only of pt implanted elsewhere)">No intervention (FU only of pt implanted elsewhere)</Select.Option>
                        <Select.Option value="First ever pacemaker/ICD system">First ever pacemaker/ICD system</Select.Option>
                        <Select.Option value="Generator change only">Generator change only</Select.Option>
                        <Select.Option value="New generator and lead(s) revision/replacement">New generator and lead(s) revision/replacement</Select.Option>
                        <Select.Option value="Upgrade - increased functionality (to DDD, CRT, or ICD)">Upgrade - increased functionality (to DDD, CRT, or ICD)</Select.Option>
                        <Select.Option value="Downgrade - decreased functionality">Downgrade - decreased functionality</Select.Option>
                        <Select.Option value="System explant">System explant</Select.Option>
                        <Select.Option value="Wound revision/generator resite/haematoma">Wound revision/generator resite/haematoma</Select.Option>
                        <Select.Option value="Lead intervention only">Lead intervention only</Select.Option>
                        <Select.Option value="Monitor procedure only">Monitor procedure only</Select.Option>
                        <Select.Option value="Other">Other</Select.Option>
                    </Select>
                </div>
                
                <div className="nhsuk-grid-column-one-half">
                    <Input
                        id="modelName"
                        label="Model Name"
                        name="modelName"
                        type="text"
                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, modelName: e.target.value })}
                        value={newImplantInstance?.modelName}
                        required
                    />
                    
                    <Input
                        id="modelNumber"
                        label="Model Number"
                        name="modelNumber"
                        type="text"
                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, modelNumber: e.target.value })}
                        value={newImplantInstance?.modelNumber}
                        required
                    />
                    
                    <Input
                        id="barcode"
                        label="Barcode/GTIN"
                        name="barcode"
                        type="text"
                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, barcode: e.target.value })}
                        value={newImplantInstance?.barcode}
                        required
                    />
                    
                    <Input
                        id="manufacturer"
                        label="Manufacturer"
                        name="manufacturer"
                        type="text"
                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, manufacturer: e.target.value })}
                        value={newImplantInstance?.manufacturer}
                        required
                    />
                    
                    <Select
                        id="modelCategory"
                        label="Model Category"
                        name="modelCategory"
                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, modelCategory: e.target.value })}
                        value={newImplantInstance?.modelCategory}
                        required
                    >
                        <Select.Option value="">Select a model category</Select.Option>
                        <Select.Option value="Accessory">Accessory</Select.Option>
                        <Select.Option value="Generator">Generator</Select.Option>
                        <Select.Option value="Lead">Lead</Select.Option>
                        <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                    </Select>
                </div>
            </div>
            
            <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-full">
                    <Textarea
                        id="notes"
                        label="Notes"
                        name="notes"
                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, notes: e.target.value })}
                        value={newImplantInstance?.notes}
                    />
                    
                    <div className="nhsuk-u-margin-top-4">
                        <Button 
                            className="nhsuk-u-margin-right-3"
                            disabled={implantInstanceUpdateProcessing}
                            onClick={handleCreateImplantInstance}
                        >
                            {implantInstanceUpdateProcessing ? 'Creating...' : 'Create Implant Record'}
                        </Button>
                        <Button secondary onClick={() => setShowCreateModal(false)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
            
            {implantInstanceCreateErrors && (
                <ErrorSummary
                    aria-labelledby="error-summary-title"
                    role="alert"
                    tabIndex="-1"
                >
                    <ErrorSummary.Title id="error-summary-title">
                        There is a problem
                    </ErrorSummary.Title>
                    <ErrorSummary.Body>
                        <p>Check the following:</p>
                        <ul className="nhsuk-list nhsuk-list--bullet">
                            {implantInstanceCreateErrors.map((error) => (
                                <li key={error}>{error}</li>
                            ))}
                        </ul>
                    </ErrorSummary.Body>
                </ErrorSummary>
            )}
            
            {successCreate && (
                <Callout
                    label="Success"
                    header="Success!"
                    autoDismiss={true}
                    dismissTime={5000}
                    time={new Date().toLocaleTimeString()}
                    title="Success!"
                    message="The implant record was created successfully."
                    mode="success"
                />
            )}
        </Modal>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                        <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Service Dashboard</h2>
                                    <Button onClick={() => setShowCreateModal(true)}>
                                        + Add New Implant
                                    </Button>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                            </div>
                        </Col>
                    </Row>
                    
                    {/* Statistics Dashboard Section */}
                    <Row className="mb-4">
                        <Col md="12">
                            {loading ? (
                                <AppSpinner />
                            ) : (
                                <>

                                    
                                    {/* ECharts Statistics View - Always displayed */}
                                    <Card>
                                        <Card.Content>
                                               
                                                <div style={{ width: '100%' }}>
                                                    {statistics ? (
                                                        <ImplantStatisticsDashboard 
                                                            statistics={statistics} 
                                                            showOnlyPieCharts={true}
                                                        />
                                                    ) : (
                                                        <InsetText>Loading statistics...</InsetText>
                                                    )}
                                            </div>
                                        </Card.Content>
                                    </Card>
                                </>
                            )}
                        </Col>
                    </Row>
                    
                    {/* Error Alerts Section */}
                    {implantInstanceCreateErrors && (
                        <Row>
                            <Col md="12">
                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        <ul className="nhsuk-list nhsuk-list--bullet">
                                            {implantInstanceCreateErrors.map((error) => (
                                                <li key={error}>{error}</li>
                                            ))}
                                        </ul>
                                    </ErrorSummary.Body>
                                </ErrorSummary>
                            </Col>
                        </Row>
                    )}
                    
                    {/* Records List Section */}
                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        <div className="nhsuk-grid-row">
                                            <div className="nhsuk-grid-column-two-thirds">
                                                Implant Records
                                            </div>
                                           
                                        </div>
                                    </Card.Heading>
                                    
                                    {/* Search and Filter Controls */}
                                    <div className="nhsuk-grid-row">
                                        <div className="nhsuk-grid-column-two-thirds">
                                            <Input
                                                id="search-implantInstances"
                                                label="Search Records"
                                                name="search-implantInstances"
                                                type="search"
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleSearchChange(e);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="nhsuk-grid-column-one-third">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                    flexWrap: 'wrap', // Allow wrapping for responsiveness
                                                }}
                                            >
                                                <Select
                                                    id="filter-implant-histories"
                                                    label="Filter by"
                                                    name="filter-implant-histories"
                                                    onChange={handleFilterChange}
                                                    value={implantInstanceListFilter.filter}
                                                    style={{ flex: '1 1 auto', minWidth: '200px', marginBottom: '8px' }} // Flexible width for responsiveness
                                                >
                                                    <Select.Option value="SerialNumber">Serial Number</Select.Option>
                                                    <Select.Option value="Patientname">Patient Name</Select.Option>
                                                    <Select.Option value="PatientNHSNumber">NHS Number</Select.Option>
                                                    <Select.Option value="Barcode">Barcode/GTIN</Select.Option>
                                                    <Select.Option value="ModelName">Model Name</Select.Option>
                                                    <Select.Option value="ModelNumber">Model Number</Select.Option>
                                                    <Select.Option value="Manufacturer">Manufacturer</Select.Option>
                                                    <Select.Option value="Type">Type</Select.Option>
                                                </Select>
                                                <Button
                                                    secondary
                                                    className="nhsuk-u-margin-left-2"
                                                    onClick={() => setimplantInstanceListFilter({ query: '', page: 1, filter: 'serialNumber' })}
                                                    style={{
                                                        height: '40px',
                                                        padding: '0 16px',
                                                        marginTop: '30px',
                                                        flex: '1 1 auto', // Flexible width for responsiveness
                                                        minWidth: '120px',
                                                    }}
                                                >
                                                    Clear Filters
                                                </Button>
                                                <Button
                                                    className="nhsuk-u-margin-left-2"
                                                    onClick={() => dispatch(listImplantInstances(implantInstanceListFilter))}
                                                    style={{
                                                        height: '40px',
                                                        padding: '0 16px',
                                                        marginTop: '30px',
                                                        flex: '1 1 auto', // Flexible width for responsiveness
                                                        minWidth: '120px',
                                                    }}
                                                >
                                                    Refresh
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* Records Table */}
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <>
                                            <Table responsive className="nhsuk-u-margin-top-3">
                                                <Table.Head>
                                                    <Table.Row>
                                                        <Table.Cell>Patient</Table.Cell>
                                                        <Table.Cell>Device</Table.Cell>
                                                        <Table.Cell>Serial Number</Table.Cell>
                                                        <Table.Cell>Status</Table.Cell>
                                                        <Table.Cell>Implant Date</Table.Cell>
                                                        <Table.Cell>Actions</Table.Cell>
                                                    </Table.Row>
                                                </Table.Head>
                                                <Table.Body>
                                                    {implantInstances && implantInstances.length > 0 ? (
                                                        implantInstances.map((implantInstance) => (
                                                            <Table.Row key={implantInstance.id}>
                                                                <Table.Cell>
                                                                    <strong>{implantInstance.patientName}</strong><br/>
                                                                    <small>{implantInstance.patientNHSNumber}</small>
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <strong>{implantInstance.manufacturer}</strong><br/>
                                                                    <small>{implantInstance.modelName}</small>
                                                                </Table.Cell>
                                                                <Table.Cell>{implantInstance.serialNumber}</Table.Cell>
                                                                <Table.Cell>
                                                                    <Tag color={
                                                                        implantInstance.status === "Implanted, connected" ? "#007f3b" :
                                                                        implantInstance.status === "Explanted" ? "#da291c" : 
                                                                        implantInstance.status === "Implanted, abandoned" ? "#ffb81c" : "#005eb8"
                                                                    }>
                                                                        {implantInstance.status}
                                                                    </Tag>
                                                                    {implantInstance.mrConditional && 
                                                                        <Tag className="nhsuk-u-margin-left-1" color="#41B6E6">MR</Tag>
                                                                    }
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {new Date(implantInstance.eventDate).toLocaleDateString()}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <div>
                                                                        <Button 
                                                                            onClick={() => handleEditImplantInstance(implantInstance.id)}
                                                                            disabled={implantInstanceDetailsLoading}
                                                                            className="nhsuk-u-margin-bottom-2"
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                    </div>
                                                                    <div>
                                                                        <Button
                                                                            onClick={() => handleDelete(implantInstance.id)}
                                                                            className="nhsuk-button--secondary nhsuk-u-margin-bottom-2"
                                                                            style={{ width: '100%' }}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    </div>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        ))
                                                    ) : (
                                                        <Table.Row>
                                                            <Table.Cell colSpan="6">
                                                                <InsetText>No implant records found. Try adjusting your search or adding a new record.</InsetText>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    )}
                                                </Table.Body>
                                            </Table>
                                            
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                handlePageChange={handlePageChange}
                                            />
                                        </>
                                    )}
                                </Card.Content>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* Modals */}
            {renderEditModal()}
            {renderCreateModal()}
        </React.Fragment>
    );
}