import {

    IMPLANT_INSTANCE_LIST_REQUEST,
    IMPLANT_INSTANCE_LIST_SUCCESS,
    IMPLANT_INSTANCE_LIST_FAIL,

    IMPLANT_INSTANCE_DETAILS_REQUEST,
    IMPLANT_INSTANCE_DETAILS_SUCCESS,
    IMPLANT_INSTANCE_DETAILS_FAIL,

    IMPLANT_INSTANCE_DELETE_REQUEST,
    IMPLANT_INSTANCE_DELETE_SUCCESS,
    IMPLANT_INSTANCE_DELETE_FAIL,

    IMPLANT_INSTANCE_CREATE_REQUEST,
    IMPLANT_INSTANCE_CREATE_SUCCESS,
    IMPLANT_INSTANCE_CREATE_FAIL,
    IMPLANT_INSTANCE_CREATE_RESET,

    IMPLANT_INSTANCE_UPDATE_REQUEST,
    IMPLANT_INSTANCE_UPDATE_SUCCESS,
    IMPLANT_INSTANCE_UPDATE_FAIL,
    IMPLANT_INSTANCE_UPDATE_RESET,

    IMPLANT_INSTANCE_COUNT_SUCCESS,
    IMPLANT_INSTANCE_COUNT_REQUEST,
    IMPLANT_INSTANCE_COUNT_FAIL,

    SET_CURRENT_PAGE,

} from "../constants/implantInstanceConstants";

const initialState = {
    ImplantInstances: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    error: null
};


export const implantInstanceListReducer = (
    state = {
        ImplantInstances: []
    }, action) => {
    switch (action.type) {
        case IMPLANT_INSTANCE_LIST_REQUEST:
            return { loading: true, implantInstances: [] };
        case IMPLANT_INSTANCE_LIST_SUCCESS:
            // our state will have a list of ImplantInstances after the request
            return {
                loading: false,
                implantInstances: action.payload.implantInstances,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                statistics: action.payload.statistics
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case IMPLANT_INSTANCE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const implantInstanceListByNhsNumberReducer = (
    state = {
        ImplantInstances: []
    }, action) => {
    switch (action.type) {
        case IMPLANT_INSTANCE_LIST_REQUEST:
            return { loading: true, implantInstances: [] };
        case IMPLANT_INSTANCE_LIST_SUCCESS:
            // our state will have a list of ImplantInstances after the request
            return {
                loading: false,
                implantInstances: action.payload.implantInstances,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                statistics: action.payload.statistics
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case IMPLANT_INSTANCE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const implantInstanceCountReducer = (state = { implantInstanceCounts: [] }, action) => {
  switch (action.type) {
    case IMPLANT_INSTANCE_COUNT_REQUEST:
      return { loading: true, implantInstanceCounts: [] };
    case IMPLANT_INSTANCE_COUNT_SUCCESS:
      return { loading: false, implantInstanceCounts: action.payload };
    case IMPLANT_INSTANCE_COUNT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const implantInstanceDetailsReducer = (
    state = {
        implantInstance: { comments: [] }
    }, action) => {
    switch (action.type) {
        case IMPLANT_INSTANCE_DETAILS_REQUEST:
            return { loading: true, ...state };
        case IMPLANT_INSTANCE_DETAILS_SUCCESS:
            // our state will have a list of ImplantInstances after the request
            return { loading: false, implantInstance: action.payload };
        case IMPLANT_INSTANCE_DETAILS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

export const implantInstanceDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case IMPLANT_INSTANCE_DELETE_REQUEST:
            return { loading: true }

        case IMPLANT_INSTANCE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case IMPLANT_INSTANCE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const implantInstanceCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case IMPLANT_INSTANCE_CREATE_REQUEST:
            return { loading: true }

        case IMPLANT_INSTANCE_CREATE_SUCCESS:
            return { loading: false, success: true, implantInstance: action.payload }

        case IMPLANT_INSTANCE_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case IMPLANT_INSTANCE_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const implantInstanceUpdateReducer = (state = { implantInstance: {} }, action) => {
    switch (action.type) {
        case IMPLANT_INSTANCE_UPDATE_REQUEST:
            return { loading: true }

        case IMPLANT_INSTANCE_UPDATE_SUCCESS:
            return { loading: false, success: true, implantInstance: action.payload }

        case IMPLANT_INSTANCE_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case IMPLANT_INSTANCE_UPDATE_RESET:
            return { implantInstance: {} }

        default:
            return state
    }
}