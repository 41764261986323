import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { Card, Table, InsetText, Select } from 'nhsuk-react-components';

// Register ECharts components
echarts.use([
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  LabelLayout,
  CanvasRenderer
]);

const ImplantStatisticsDashboard = ({ statistics }) => {
  // State for top K selection
  const [topK, setTopK] = useState(10);
  
  // Chart refs
  const manufacturerChartRef = useRef(null);
  const typeChartRef = useRef(null);
  const modelNameChartRef = useRef(null);
  const modelNumberChartRef = useRef(null);

  // NHS color palette
  const colorPalette = [
    '#005EB8', '#4C90CD', '#7FABE0', '#B3C7F2', 
    '#00A499', '#40BDB3', '#80D5CC', '#BFECEA',
    '#768692', '#99A5AD', '#BBC3C8', '#DEE1E3'
  ];

  // Process data to get top K elements and group the rest as "Other"
  const processTopK = (dataObject, k) => {
    if (!dataObject) return [];
    
    // Convert to array and sort by value (count)
    const sortedArray = Object.entries(dataObject)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value);
    
    // Take top K elements
    const topKItems = sortedArray.slice(0, k);
    
    // Sum the rest as "Other" if there are more than K elements
    if (sortedArray.length > k) {
      const otherSum = sortedArray
        .slice(k)
        .reduce((sum, item) => sum + item.value, 0);
      
      if (otherSum > 0) {
        topKItems.push({ name: "Other", value: otherSum });
      }
    }
    
    return topKItems;
  };

  const getPieChartOption = (data, title) => ({
    title: {
      text: title,
      left: 'center',
      top: 0,
      textStyle: {
        fontSize: 16,
        fontWeight: 'bold'
      }
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)'
    },
    legend: {
      type: 'scroll',
      orient: 'horizontal',
      bottom: 10,
      left: 'center'
    },
    color: colorPalette,
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '50%'],
        label: {
          show: true,
          formatter: '{b}\n{d}%'
        },
        data: data
      }
    ]
  });

  const renderChart = (ref, data, title) => {
    if (ref.current) {
      try {
        const chart = echarts.init(ref.current);
        chart.setOption(getPieChartOption(data, title));

        const resizeHandler = () => chart.resize();
        window.addEventListener('resize', resizeHandler);

        return () => {
          window.removeEventListener('resize', resizeHandler);
          chart.dispose();
        };
      } catch (error) {
        console.error(`Chart rendering error for ${title}:`, error);
      }
    }
  };

  useEffect(() => {
    if (!statistics) return;

    const data = {
      manufacturer: processTopK(statistics.countByManufacturer, topK),
      type: processTopK(statistics.countByType, topK),
      modelName: processTopK(statistics.countByModelName, topK),
      modelNumber: processTopK(statistics.countByModelNumber, topK)
    };

    const cleanups = [
      renderChart(manufacturerChartRef, data.manufacturer, 'Manufacturer Distribution'),
      renderChart(typeChartRef, data.type, 'Type Distribution'),
      renderChart(modelNameChartRef, data.modelName, 'Model Name Distribution'),
      renderChart(modelNumberChartRef, data.modelNumber, 'Model Number Distribution')
    ];

    return () => {
      cleanups.forEach(cleanup => cleanup?.());
    };
  }, [statistics, topK]);

  // Table Data for Manufacturer & Type with topK applied
  const createSummaryTableData = (stats) => {
    const formatData = (category, data, k) => {
      // Process top K for table display
      const processedData = processTopK(data, k);
      return processedData.map(item => ({
        category,
        name: item.name,
        count: item.value,
        percentage: ((item.value / stats.totalCount) * 100).toFixed(2)
      }));
    };

    return {
      manufacturer: formatData('Manufacturer', stats.countByManufacturer, topK),
      type: formatData('Type', stats.countByType, topK),
      modelName: formatData('Model Name', stats.countByModelName, topK),
      modelNumber: formatData('Model Number', stats.countByModelNumber, topK)
    };
  };

  if (!statistics) {
    return <InsetText>No statistics available</InsetText>;
  }

  const summaryTableData = createSummaryTableData(statistics);

  // Options for dropdown
  const topKOptions = [
    { value: 3, label: 'Top 3' },
    { value: 5, label: 'Top 5' },
    { value: 10, label: 'Top 10' },
    { value: 15, label: 'Top 15' },
    { value: 20, label: 'Top 20' },
    { value: 50, label: 'Top 50' },
    { value: 100, label: 'Top 100' },
    { value: -1, label: 'Show All' }
  ];

  // Handler for dropdown change
  const handleTopKChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setTopK(value);
  };

  return (
    <div>
      <h2>Implant Statistics</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <p>Total: <strong>{statistics.totalCount}</strong></p>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Select
            hint='Show:'
            id="topKDropdown"
            onChange={handleTopKChange}
            value={topK}
          >
            {topKOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </div>
      </div>
      {/* Horizontally Scrollable Container */}
      <div style={{ overflowX: 'auto', padding: '10px 0' }}>
        <div style={{ 
          display: 'flex', 
          flexWrap: 'nowrap', 
          justifyContent: 'flex-start', 
          gap: '20px', 
        }}>
          {/* Summary Tables - Vertically Aligned */}
          <div style={{ flex: '0 0 600px', maxWidth: '600px' }}>
            <Card>
              <Card.Content>
                <Card.Heading>Summary</Card.Heading>
                <div style={{ height: '400px', overflowY: 'auto' }}>
                  {['manufacturer', 'type', 'modelName', 'modelNumber'].map((key, index) => (
                    <div key={index} style={{ marginBottom: '20px' }}>
                      <h4>{summaryTableData[key][0]?.category} Distribution</h4>
                      <Table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Count</th>
                            <th>Percentage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {summaryTableData[key].map((item, index) => (
                            <tr key={index}>
                              <td style={{ paddingRight: '10px' }}>{item.name}</td>
                              <td style={{ paddingRight: '10px' }}>{item.count}</td>
                              <td style={{ paddingRight: '10px' }}>{item.percentage}%</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ))}
                </div>
              </Card.Content>
            </Card>
          </div>

          {/* Charts - Same Width and Height as Table */}
          {[{ ref: manufacturerChartRef, title: 'Manufacturer Distribution' }, 
            { ref: typeChartRef, title: 'Type Distribution' },
            { ref: modelNameChartRef, title: 'Model Name Distribution' },
            { ref: modelNumberChartRef, title: 'Model Number Distribution' }
          ].map(({ ref, title }, index) => (
            <div key={index} style={{ flex: '0 0 600px', maxWidth: '600px' }}>
              <Card>
                <Card.Content>
                  <Card.Heading>{title}</Card.Heading>
                  <div ref={ref} style={{ height: '400px', width: '100%' }}></div>
                </Card.Content>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImplantStatisticsDashboard;