import React from "react";
import { Redirect } from "react-router-dom";

// Auth screens
import RegisterScreen from "../screens/RegisterScreen";
import LoginScreen from "../screens/LoginScreen";
import DashboardScreen from "../screens/DashboardScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen";
import ResetPasswordSentScreen from "../screens/ResetPasswordSentScreen";
import ResetPasswordSetupScreen from "../screens/ResetPasswordSetupScreen";
import TwoFACodeRequestScreen from "../screens/TwoFACodeRequestScreen";
import EmailConfirmedScreen from "../screens/EmailConfirmedScreen";

// Identity user
import EditUserScreen from "../screens/users/EditUserScreen";
import UserManagementScreen from "../screens/users/UserManagementScreen";
import CreateUserScreen from "../screens/users/CreateUserScreen";
// Patient screens
import PatientListScreen from "../screens/patients/PatientListScreen";
import PatientEditScreen from "../screens/patients/PatientEditScreen";
import PatientCreateScreen from "../screens/patients/PatientCreateScreen";
import PatientProfileScreen from "../screens/patients/PatientProfileScreen";

// Organisation screens
import OrganisationCRUDScreen from "../screens/organisations/OrganisationCRUDScreen";

// Symptom screens
import SymptomCRUDScreen from "../screens/symptoms/SymptomCRUDScreen";

// Aetiology screens
import AetiologyCRUDScreen from "../screens/aetiologies/AetiologyCRUDScreen";

// Ecg screens
import EcgCRUDScreen from "../screens/ecgs/EcgCRUDScreen";

// FollowupCentre screens
import FollowupCentreCRUDScreen from "../screens/followupCentres/FollowupCentreCRUDScreen";

// ImplantCentre screens
import ImplantCentreCRUDScreen from "../screens/implantCentres/ImplantCentreCRUDScreen";

// Implant screens
import ImplantCRUDScreen from "../screens/implants/ImplantCRUDScreen";

// Smtp settings
import SmtpConfigCRUDScreen from "../screens/settings/SmtpConfigCRUDScreen";

// Template screens
import TemplateCRUDScreen from "../screens/settings/TemplateCRUDScreen";

// consents
import ConsentCRUDScreen from "../screens/settings/ConsentCRUDScreen";

// data import export
import DataImportExportScreen from "../screens/settings/DataImportExportScreen";

// Patient emergency Access screens
import PatientEmergencyAccessScreen from "../screens/patients/PatientEmergencyAccessScreen";

// Implant History
import ImplantHistoryScreen from "../screens/implantHistories/ImplantHistoryCRUDScreen";

// Implant Instance
import ImplantInstanceCRUDScreen from "../screens/implantInstances/ImplantInstanceCRUDScreen";

// Settings
import SettingsScreen from "../screens/SettingsScreen";

// Unauthorized
import UnauthorizedScreen from "../screens/UnauthorizedScreen";

// Not Found
import NotFoundScreen from "../screens/NotFoundScreen";

// Home screen
import HomeScreen from "../screens/HomeScreen";

const allRoles = ["SuperUser", "TrustUserManager", "CardiologyStaff", "Patient", "UnauthorizedUser"];

// Private routes
const userRoutes = [
  // Dashboard
  { path: "/dashboard", component: DashboardScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff", "Patient", "UnauthorizedUser"]},
  // Redirect to /dashboard by default
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  { path : "/unauthorized", component: UnauthorizedScreen, roles: allRoles },
  // Patient Profile
  { path: "/myprofile", component: PatientProfileScreen, roles: ["Patient"]},
  // User Management
  { path: "/user_management", component: UserManagementScreen, roles: ["SuperUser", "TrustUserManager"] },
  // User Create
  { path: "/users/create", component: CreateUserScreen, roles: ["SuperUser", "TrustUserManager"]},
    // Profile
  { path: "/profile/:_id", component: EditUserScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]},
  // Patient
  { path: "/patients", component: PatientListScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]},
  // Patient Edit
  { path: "/patient/:_id", component: PatientEditScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]},
  // Patient Create
  { path: "/patients/create", component: PatientCreateScreen, roles: ["SuperUser", "CardiologyStaff"]},
  // Settings
  { path: "/settings", component: SettingsScreen, roles: ["SuperUser", "TrustUserManager"]},
  // Data Import Export
  { path: "/data_import_export", component: DataImportExportScreen, roles: ["SuperUser, TrustUserManager"]},
  // Organisation
  { path: "/organisations", component: OrganisationCRUDScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]},
  // Symptom
  { path: "/symptoms", component: SymptomCRUDScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]},
  // Aeitology
  { path: "/aetiologies", component: AetiologyCRUDScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]},
  // Ecg
  { path: "/ecgs", component: EcgCRUDScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]},
  // Follow up
  { path: "/followup_centres", component: FollowupCentreCRUDScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]},
  // Implant centre
  { path: "/implant_centres", component: ImplantCentreCRUDScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]},
  // Implant
  { path: "/implants", component: ImplantCRUDScreen, roles: ["SuperUser", "TrustUserManager"]},
  // Email Settings
  { path: "/settings/email", component: SmtpConfigCRUDScreen, roles: ["SuperUser"]},
  // Template
  { path: "/settings/templates", component: TemplateCRUDScreen, roles: ["SuperUser"]},
  // consents
  { path: "/settings/consents", component: ConsentCRUDScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]},
  // data import export
  { path: "/settings/data_import_export", component: DataImportExportScreen, roles: ["SuperUser", "TrustUserManager"]},
  // Implant History
  { path: "/implant_alerts", component: ImplantHistoryScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]},
  // implant Instance
  { path: "/service_dashboard", component: ImplantInstanceCRUDScreen, roles: ["SuperUser", "TrustUserManager", "CardiologyStaff"]}
];

// public routes
const authRoutes = [
  // Authencation page
  { path : "/home", component: HomeScreen },
  { path: "/register", component: RegisterScreen},
  { path: "/login", component: LoginScreen},
  { path: "/resetpassword", component: ResetPasswordScreen },
  { path: "/resetpasswordsent", component: ResetPasswordSentScreen },
  { path: "/resetpasswordsetup", component: ResetPasswordSetupScreen },
  { path: "/2fauth", component: TwoFACodeRequestScreen },
  { path: "/email_confirmed", component: EmailConfirmedScreen },
  { path: "/patient/emergency/:_id", component: PatientEmergencyAccessScreen },
  { path: "/not_found", component: NotFoundScreen }

];

export { userRoutes, authRoutes };