import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateDetailsByName } from '../../actions/templateActions';
import { Button } from "nhsuk-react-components";
import QRCode from "react-qr-code";
import axios from 'axios';
import html2pdf from 'html2pdf.js';

const PhysicalCard = ({ emailId, recipientFullName, nhsNumber, birthdate, etiologies, symptoms, ecgIndications, mriConditional, generator, leads, emergencyAccessUrl }) => {
  const dispatch = useDispatch();
  const templateDetailsByName = useSelector((state) => state.templateDetailsByName);
  const { loading, error, template } = templateDetailsByName;

  const [card, setCard] = useState({});
  const [zoomLevel, setZoomLevel] = useState(100);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [qrCodeBase64, setQrCodeBase64] = useState('');

  // Generate QR code as base64
  useEffect(() => {
    if (emergencyAccessUrl) {
      generateQrCodeBase64(emergencyAccessUrl);
    }
  }, [emergencyAccessUrl]);

  // Generate QR code as base64 string
  const generateQrCodeBase64 = (url) => {
    const qrCodeSvg = document.createElement('div');
    qrCodeSvg.style.position = 'absolute';
    qrCodeSvg.style.left = '-9999px';
    document.body.appendChild(qrCodeSvg);
    
    const qrElement = <QRCode value={url} size={128} />;
    ReactDOM.render(qrElement, qrCodeSvg, () => {
      const svgElement = qrCodeSvg.querySelector('svg');
      const svgString = new XMLSerializer().serializeToString(svgElement);
      const base64 = btoa(svgString);
      setQrCodeBase64(`data:image/svg+xml;base64,${base64}`);
      document.body.removeChild(qrCodeSvg);
    });
  };

  // Dispatch the action to get the template
  useEffect(() => {
    dispatch(getTemplateDetailsByName('physicalCard'));
  }, [dispatch]);

  // Update template with data
  useEffect(() => {
    if (template?.htmlContent && qrCodeBase64) {
      let cardContent = template.htmlContent;
      cardContent = cardContent.replace('{recipientFullName}', recipientFullName);
      cardContent = cardContent.replace('{nhsNumber}', nhsNumber);
      cardContent = cardContent.replace('{birthdate}', birthdate);
      cardContent = cardContent.replace('{etiologies}', etiologies || '-');
      cardContent = cardContent.replace('{symptoms}', symptoms || '-');
      cardContent = cardContent.replace('{ecgIndications}', ecgIndications || '-');
      cardContent = cardContent.replace('{mriConditional}', mriConditional);
      cardContent = cardContent.replace('{generator}', generator || '-');
      cardContent = cardContent.replace('{leads}', leads || '-');
      cardContent = cardContent.replace('{emergencyAccessUrl}', emergencyAccessUrl || '#');
      cardContent = cardContent.replace('{qrCodeBase64}', qrCodeBase64);
      setCard(cardContent);
    }
  }, [template, nhsNumber, birthdate, etiologies, symptoms, ecgIndications, mriConditional, generator, leads, emergencyAccessUrl, recipientFullName, qrCodeBase64]);

  const generatePDF = () => {
    return new Promise((resolve, reject) => {
      const element = document.getElementById('card-content');
      const options = {
        filename: 'physical_card.pdf',
        jsPDF: { unit: 'mm', format: [172, 108.5], orientation: 'l' },
        Image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
      };

      html2pdf().from(element).set(options).outputPdf('blob')
        .then(pdf => {
          resolve(pdf);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  const downloadPDF = async () => {
    try {
      const pdf = await generatePDF();
      const url = URL.createObjectURL(pdf);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'physical_card.pdf');
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const sendEmail = async () => {
    try {
      setSendingEmail(true);
      const pdf = await generatePDF();

      const pdfBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(pdf);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
      });

      const email = {
        "EmailToId": emailId,
        "EmailToName": recipientFullName,
        "EmailSubject": "Your Physical Card",
        "EmailBody": `Dear ${recipientFullName},<br> Please find your physical card attached.<br><br> Best regards,<br>Your Healthcare Team`,
        "Attachments": [
          {
            "Content": pdfBase64,
            "FileName": nhsNumber + "_physical_card.pdf",
            "ContentType": "application/pdf"
          }
        ]
      };

      await axios.post("/api/v1/auth/sendemail", email, {
        headers: { 'Content-Type': 'application/json' }
      });
      alert("Email sent successfully!");
    } catch (error) {
      console.error(error);
      alert("Failed to send email: " + error.message);
    } finally {
      setSendingEmail(false);
    }
  };

  const zoomStyle = {
    transform: `scale(${zoomLevel / 100})`,
    transformOrigin: 'top left',
  };

  return (
    <React.Fragment>
      <div>
        <Button onClick={downloadPDF}>Download PDF</Button>
        {' '}
        <Button onClick={sendEmail} disabled={sendingEmail}>
          {sendingEmail ? "Sending..." : "Send as Email"}
        </Button>
      </div>
      {(loading || !card) ? (
        <h2>Loading...</h2>
      ) : error ? (
        <h3>{error}</h3>
      ) : (
        <div id="card-content" style={zoomStyle} className="card-content" dangerouslySetInnerHTML={{ __html: card }} />
      )}
    </React.Fragment>
  );
};

export default PhysicalCard;