import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import { Link } from "react-router-dom";
import { WarningCallout, Card } from 'nhsuk-react-components';
import NHSPatientLogin from "../components/nhsCis2Login/NHSPatientLogin.component";
import NHSMailLogin from "../components/nhsCis2Login/NHSMailLogin.component";

function LoginScreen({ history, location }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resetMessage, setResetMessage] = useState('');
    const [isPatient, setIsPatient] = useState(false);
    const [loginError, setLoginError] = useState(null);

    const dispatch = useDispatch();

    const redirect = location.search ? location.search.split('=')[1] : '/';

    const userLogin = useSelector(state => state.userLogin);
    const { loading, success, error, userInfo } = userLogin;

    useEffect(() => {
        document.title = "Login - MyHeartPassport";
    }, []);

    useEffect(() => {
        if (userInfo && success) {
            history.push(redirect ? redirect : '/');
        }
    }, [userInfo, success, history, redirect]);

    useEffect(() => {
        if (error) {
            setLoginError(error);
        }
    }, [error]);

    const submitHandler = (e) => {
        e.preventDefault();
        setLoginError(null);
        dispatch(login(email, password));
    };

    const handleLoginSuccess = (userInfo) => {
        history.push('/dashboard');
    };

    const toggleUserType = () => {
        setIsPatient(!isPatient);
        setLoginError(null);
    };

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper" id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    <div className="nhsuk-grid-column-two-thirds" style={{ margin: "0 auto", float: "none" }}>
                        <Card style={{ 
                            boxShadow: "0 4px 8px rgba(0,0,0,0.05)", 
                            border: "1px solid #f0f4f5",
                            borderRadius: "8px"
                        }}>
                            <Card.Content>
                                <div style={{ 
                                    display: "flex", 
                                    justifyContent: "space-between", 
                                    alignItems: "center",
                                    marginBottom: "24px",
                                    borderBottom: "1px solid #f0f4f5",
                                    paddingBottom: "16px"
                                }}>
                                    <h1 style={{ margin: 0, fontSize: "1.5rem" }}>
                                        {isPatient ? 'Patient Login' : 'Staff Login'}
                                    </h1>
                                    <div>
                                        <a 
                                            href="#" 
                                            onClick={toggleUserType} 
                                            className="nhsuk-link" 
                                            style={{ 
                                                display: "inline-flex", 
                                                alignItems: "center" 
                                            }}
                                        >
                                            <span style={{ marginRight: "5px" }}>➤</span>
                                            I am a {isPatient ? 'staff member' : 'patient'}
                                        </a>
                                    </div>
                                </div>

                                {loginError && (
                                    <div className="nhsuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1">
                                        <h3 className="nhsuk-error-summary__title" id="error-summary-title">
                                            There is a problem
                                        </h3>
                                        <div className="nhsuk-error-summary__body">
                                            <ul className="nhsuk-list nhsuk-error-summary__list" role="list">
                                                <li>{loginError}</li>
                                            </ul>
                                        </div>
                                    </div>
                                )}

                                <form onSubmit={submitHandler}>
                                    <div className="nhsuk-form-group">
                                        <label className="nhsuk-label" htmlFor="email">
                                            Email
                                        </label>
                                        <input
                                            className="nhsuk-input"
                                            id="email"
                                            name="email"
                                            type="text"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            required
                                            style={{ borderRadius: "4px" }}
                                        />
                                    </div>

                                    <div className="nhsuk-form-group">
                                        <label className="nhsuk-label" htmlFor="password">
                                            Password
                                        </label>
                                        <input
                                            className="nhsuk-input"
                                            id="password"
                                            name="password"
                                            type="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            required
                                            style={{ borderRadius: "4px" }}
                                        />
                                    </div>

                                    <button 
                                        className="nhsuk-button" 
                                        data-module="nhsuk-button" 
                                        type="submit" 
                                        style={{ 
                                            width: '100%', 
                                            marginTop: '24px',
                                            marginBottom: '16px',
                                            borderRadius: '4px',
                                            fontWeight: 'bold'
                                        }} 
                                        disabled={loading}
                                    >
                                        {loading ? 'Authenticating...' : 'Sign In'}
                                    </button>
                                </form>

                                

                                <div>
                                    {isPatient ? (
                                        <WarningCallout label="Warning callout">
                                            <WarningCallout.Label>Important information</WarningCallout.Label>
                                            <p>If you can't login to your account, please contact your administrator.</p>
                                        </WarningCallout>
                                    ) : (
                                        <>
                                        <div style={{ 
                                            display: "flex", 
                                            alignItems: "center", 
                                            textAlign: "center", 
                                        }}>
                                            <hr style={{ flex: 1, border: "none", borderTop: "1px solid #f0f4f5" }} />
                                            <span style={{ padding: "0 10px", color: "#4c6272" }}>OR</span>
                                            <hr style={{ flex: 1, border: "none", borderTop: "1px solid #f0f4f5" }} />
                                        </div>
                                        <div style={{ 
                                            background: "#f0f4f5", 
                                            borderRadius: "4px",
                                        }}>
                                            <NHSMailLogin onLoginSuccess={handleLoginSuccess} />
                                        </div>
                                    </>
                                    )}
                                </div>
                                <div style={{ 
                                    marginTop: "16px", 
                                    display: "flex", 
                                    justifyContent: "space-between", 
                                    flexWrap: "wrap",
                                    borderTop: "1px solid #f0f4f5",
                                    paddingTop: "16px"
                                }}>
                                    <p>
                                        <Link 
                                            to={redirect ? `/resetpasswordsetup?redirect=${redirect}` : "/resetpasswordsetup"} 
                                            className="nhsuk-link"
                                        >
                                            Forgotten password?
                                        </Link>
                                    </p>
                                    <p>
                                        <Link 
                                            to={redirect ? `/register?redirect=${redirect}` : "/register"} 
                                            className="nhsuk-link nhsuk-link--no-visited-state"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            Register for an account
                                        </Link>
                                    </p>
                                </div>

                                {resetMessage && (
                                    <WarningCallout>
                                        <p>{resetMessage}</p>
                                    </WarningCallout>
                                )}
                            </Card.Content>
                        </Card>

                        <p style={{ 
                            marginTop: "24px", 
                            fontSize: "0.9rem", 
                            textAlign: "center",
                            color: "#4c6272"
                        }}>
                            By using this service you are agreeing to our <a href="#" className="nhsuk-link">terms of use</a> and <a href="https://www.bartshealth.nhs.uk/privacy" className="nhsuk-link">privacy policy</a>.
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default LoginScreen;