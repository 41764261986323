import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { register } from "../actions/userActions";
import { listOrganisations } from "../actions/organisationActions";
import { Select, Card, WarningCallout } from "nhsuk-react-components";
import NHSMailLogin from "../components/nhsCis2Login/NHSMailLogin.component";

function RegisterScreen({ history, location }) {
    useEffect(() => {
        document.title = "Register - MyHeartPassport";
    }, []);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [organisationId, setOrganisationId] = useState("");
    const [isPatient, setIsPatient] = useState(false);
    const [errors, setErrors] = useState({});
    
    const dispatch = useDispatch();

    const redirect = location.search ? location.search.split("=")[1] : "/";

    const userRegister = useSelector((state) => state.userRegister);
    const { error, loading, success } = userRegister;

    const organisationList = useSelector((state) => state.organisationList);
    const { organisations, loading: loadingOrganisations, error: errorOrganisations } = organisationList;

    useEffect(() => {
        dispatch(listOrganisations());
    }, [dispatch]);

    useEffect(() => {
        if (organisations && organisations.length > 0) {
            setOrganisationId(organisations[0].id);
        }
    }, [organisations]);

    useEffect(() => {
        if (success) {
            window.location.href = "/login";
        }
    }, [success]);

    const submitHandler = async (e) => {
        e.preventDefault();
        let validationErrors = {};

        if (password.length < 6) {
            validationErrors.password = "Password must be at least 6 characters long.";
        }
        if (password !== confirmPassword) {
            validationErrors.confirmPassword = "Passwords do not match.";
        }
        if (organisationId === "") {
            validationErrors.organisationId = "Please select an organisation.";
        }
        if (email === "") {
            validationErrors.email = "Please enter your email address.";
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setErrors({});
        dispatch(register(email, password, organisationId));
    };

    const handleLoginSuccess = (userInfo) => {
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        window.location.href = "/login";
    };

    const toggleUserType = () => {
        setIsPatient(!isPatient);
        setMessage("");
    };

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper" id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    <div className="nhsuk-grid-column-two-thirds" style={{ margin: "0 auto", float: "none" }}>
                        <Card style={{ 
                            boxShadow: "0 4px 8px rgba(0,0,0,0.05)", 
                            border: "1px solid #f0f4f5",
                            borderRadius: "8px"
                        }}>
                            <Card.Content>
                                <div style={{ 
                                    display: "flex", 
                                    justifyContent: "space-between", 
                                    alignItems: "center",
                                    marginBottom: "24px",
                                    borderBottom: "1px solid #f0f4f5",
                                    paddingBottom: "16px"
                                }}>
                                    <h1 style={{ margin: 0, fontSize: "1.5rem" }}>
                                        {isPatient ? 'Patient Registration' : 'Staff Registration'}
                                    </h1>
                                    <div>
                                        <a 
                                            href="#" 
                                            onClick={toggleUserType} 
                                            className="nhsuk-link" 
                                            style={{ 
                                                display: "inline-flex", 
                                                alignItems: "center" 
                                            }}
                                        >
                                            <span style={{ marginRight: "5px" }}>➤</span>
                                            I am a {isPatient ? 'staff member' : 'patient'}
                                        </a>
                                    </div>
                                </div>

                                {error && (
                                    <div className="nhsuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1">
                                        <h3 className="nhsuk-error-summary__title" id="error-summary-title">
                                            There is a problem
                                        </h3>
                                        <div className="nhsuk-error-summary__body">
                                            <ul className="nhsuk-list nhsuk-error-summary__list" role="list">
                                                <li>{error}</li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                
                                {message && (
                                    <div className="nhsuk-panel nhsuk-panel--confirmation nhsuk-u-margin-top-0 nhsuk-u-margin-bottom-4">
                                        <h5 className="nhsuk-panel__title" style={{ color: "green" }}>
                                            {message}
                                        </h5>
                                    </div>
                                )}

                                <form onSubmit={submitHandler}>
                                    <div className="nhsuk-form-group">
                                        <Select
                                            label="Organisation"
                                            disabled={loadingOrganisations}
                                            onChange={(e) => setOrganisationId(e.target.value)}
                                            value={organisationId}
                                            required
                                            error={errors.organisationId}
                                        >
                                            {loadingOrganisations ? (
                                                <Select.Option value="">Loading organisations...</Select.Option>
                                            ) : errorOrganisations ? (
                                                <Select.Option value="">{errorOrganisations}</Select.Option>
                                            ) : (
                                                organisations && organisations.map((organisation) => (
                                                    <Select.Option key={organisation.id} value={organisation.id}>
                                                        {organisation.name}
                                                    </Select.Option>
                                                ))
                                            )}
                                        </Select>
                                    </div>

                                    <div className="nhsuk-form-group">
                                        <label className="nhsuk-label" htmlFor="email">
                                            Email
                                        </label>
                                        <input
                                            className="nhsuk-input"
                                            id="email"
                                            name="email"
                                            type="text"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            required
                                            style={{ borderRadius: "4px" }}
                                            aria-invalid={errors.email ? "true" : "false"}
                                            aria-describedby={errors.email ? "email-error" : undefined}
                                        />
                                        {errors.email && (
                                            <span className="nhsuk-error-message" id="email-error">
                                                {errors.email}
                                            </span>
                                        )}
                                    </div>

                                    <div className="nhsuk-form-group">
                                        <label className="nhsuk-label" htmlFor="password">
                                            Password
                                        </label>
                                        <input
                                            className="nhsuk-input"
                                            id="password"
                                            name="password"
                                            type="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            required
                                            style={{ borderRadius: "4px" }}
                                            aria-invalid={errors.password ? "true" : "false"}
                                            aria-describedby={errors.password ? "password-error" : undefined}
                                        />
                                        {errors.password && (
                                            <span className="nhsuk-error-message" id="password-error">
                                                {errors.password}
                                            </span>
                                        )}
                                    </div>

                                    <div className="nhsuk-form-group">
                                        <label className="nhsuk-label" htmlFor="confirmPassword">
                                            Confirm Password
                                        </label>
                                        <input
                                            className="nhsuk-input"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            type="password"
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            value={confirmPassword}
                                            required
                                            style={{ borderRadius: "4px" }}
                                            aria-invalid={errors.confirmPassword ? "true" : "false"}
                                            aria-describedby={errors.confirmPassword ? "confirmPassword-error" : undefined}
                                        />
                                        {errors.confirmPassword && (
                                            <span className="nhsuk-error-message" id="confirmPassword-error">
                                                {errors.confirmPassword}
                                            </span>
                                        )}
                                    </div>

                                    <button 
                                        className="nhsuk-button" 
                                        data-module="nhsuk-button" 
                                        type="submit" 
                                        style={{ 
                                            width: '100%', 
                                            marginTop: '24px',
                                            marginBottom: '16px',
                                            borderRadius: '4px',
                                            fontWeight: 'bold'
                                        }} 
                                        disabled={loading}
                                    >
                                        {loading ? 'Sending Registration Request...' : 'Register'}
                                    </button>
                                </form>

                                {isPatient && (
                                    <WarningCallout label="Warning callout">
                                        <WarningCallout.Label>Important information</WarningCallout.Label>
                                        <p>If you can't register your account, please contact your administrator.</p>
                                    </WarningCallout>
                                )}

                                {!isPatient && (
                                    <>
                                        <div style={{ 
                                            display: "flex", 
                                            alignItems: "center", 
                                            textAlign: "center", 
                                        }}>
                                            <hr style={{ flex: 1, border: "none", borderTop: "1px solid #f0f4f5" }} />
                                            <span style={{ padding: "0 10px", color: "#4c6272" }}>OR</span>
                                            <hr style={{ flex: 1, border: "none", borderTop: "1px solid #f0f4f5" }} />
                                        </div>
                                        <div style={{ 
                                            background: "#f0f4f5", 
                                            borderRadius: "4px",
                                        }}>
                                            <NHSMailLogin onLoginSuccess={handleLoginSuccess} />
                                        </div>
                                    </>
                                )}

                                <div style={{ 
                                    textAlign: "center",
                                    borderTop: "1px solid #f0f4f5",
                                }}>
                                    <p>
                                        Already have an account?{" "}
                                        <Link
                                            to={redirect ? `/login?redirect=${redirect}` : "/login"}
                                            className="nhsuk-link nhsuk-link--no-visited-state"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            Sign in now
                                        </Link>
                                    </p>
                                </div>
                            </Card.Content>
                        </Card>

                        <p style={{ 
                            marginTop: "24px", 
                            fontSize: "0.9rem", 
                            textAlign: "center",
                            color: "#4c6272"
                        }}>
                            By using this service you are agreeing to our <a href="#" className="nhsuk-link">terms of use</a> and <a href="https://www.bartshealth.nhs.uk/privacy" className="nhsuk-link">privacy policy</a>.
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default RegisterScreen;