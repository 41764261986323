import React from "react";

function Footer() {
    const version = process.env.REACT_APP_VERSION || "UNKNOWN";
    const items = [
        { name: "Feedback", link: "https://www.bartshealth.nhs.uk/feedback" },
        { name: "Get in touch", link: "https://www.bartshealth.nhs.uk/pals" },
        { name: "Terms and conditions", link: "https://www.bartshealth.nhs.uk/privacy" },
        { name: "Your privacy", link: "https://www.bartshealth.nhs.uk/privacy" },
        // ...other items...
    ].slice(0, 20); // Limit to top 20 items

    return (
        <footer role="contentinfo">
            <div className="nhsuk-footer-container">
                <div className="nhsuk-width-container">
                    <h2 className="nhsuk-u-visually-hidden">Support links</h2>
                    <ul className="nhsuk-footer__list">
                        {items.map((item, index) => (
                            <li key={index} className="nhsuk-footer__list-item nhsuk-footer-default__list-item">
                                <a className="nhsuk-footer__list-item-link" href={item.link}>{item.name}</a>
                            </li>
                        ))}
                    </ul>
                    <div>
                        <p className="nhsuk-footer__copyright">© Crown copyright - Build {version}</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;