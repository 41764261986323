import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { WarningCallout, InsetText, Card } from "nhsuk-react-components";
import { logout } from "../actions/userActions";

function TwoFACodeRequestScreen({ history }) {
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['codeRequestCount', 'codeRequestBlockTime']);

    useEffect(() => {
        document.title = "2-Factor Authentication - MyHeartPassport";
    }, []);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const [code, setCode] = useState('');
    const [TFAMessage, setTFAMessage] = useState('');
    const [sendingCode, setSendingEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [isBlocked, setIsBlocked] = useState(false);

    useEffect(() => {
        if (!userInfo || !userInfo.email) {
            history.push("/login");
        } else {
            setEmail(userInfo.email);
            checkBlockStatus();
            handle2FACodeRequest(userInfo.email);
        }
    }, [history, userInfo]);

    const checkBlockStatus = () => {
        const blockTime = cookies.codeRequestBlockTime;
        if (blockTime && new Date(blockTime) > new Date()) {
            setIsBlocked(true);
            const timeLeft = Math.ceil((new Date(blockTime) - new Date()) / (1000 * 60));
            setTFAMessage(`Too many attempts. Please try again in ${timeLeft} minutes.`);
        } else {
            setIsBlocked(false);
            setCookie('codeRequestCount', 0, { path: '/' });
            setCookie('codeRequestBlockTime', '', { path: '/' });
        }
    };

    const handle2FACodeRequest = async (email) => {
        if (isBlocked) return;

        const currentCount = cookies.codeRequestCount || 0;
        if (currentCount >= 5) {
            const blockTime = new Date(new Date().getTime() + 60 * 60 * 1000); // 1 hour from now
            setCookie('codeRequestBlockTime', blockTime.toISOString(), { path: '/' });
            setIsBlocked(true);
            setTFAMessage("Too many attempts. Please try again in 1 hour.");
            return;
        }

        setSendingEmail(true);
        setTFAMessage(`Sending code to ${email} ...`);
        try {
            const response = await axios.post(`/api/v1/auth/sendverificationcode`, {
                "email": email
            });

            setSendingEmail(false);
            setTFAMessage(`Code successfully sent to ${userInfo.email}.`);
            setCookie('codeRequestCount', currentCount + 1, { path: '/' });
        } catch (error) {
            console.log(error);
            setSendingEmail(false);
            setTFAMessage("2FA code request failed.");
        }
    };

    const handle2FACodeSubmission = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`/api/v1/auth/verifycode`, {
                "email": email,
                "code": code
            });

            setLoading(false);
            setTFAMessage(" ✅ 2FA code verified successfully. Redirecting shortly ...");
            localStorage.setItem("2fauthenticated", "true");

            setTimeout(() => {
                history.push("/");
            }, 2000);

        } catch (error) {
            setLoading(false);
            setTFAMessage("❌ 2FA code verification failed.");
        }
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <div className="nhsuk-width-container">
            <main className="nhsuk-main-wrapper" id="maincontent" role="main">
                <div className="nhsuk-grid-row">
                    <div className="nhsuk-grid-column-two-thirds" style={{ margin: "0 auto", float: "none" }}>
                        <Card style={{ 
                            boxShadow: "0 4px 8px rgba(0,0,0,0.05)", 
                            border: "1px solid #f0f4f5",
                            borderRadius: "8px"
                        }}>
                            <Card.Content>
                                <div style={{ 
                                    display: "flex", 
                                    justifyContent: "space-between", 
                                    alignItems: "center",
                                    marginBottom: "24px",
                                    borderBottom: "1px solid #f0f4f5",
                                    paddingBottom: "16px"
                                }}>
                                    <h1 style={{ margin: 0, fontSize: "1.5rem" }}>
                                        2-Factor Authentication
                                    </h1>
                                </div>
                                
                                <InsetText>
                                    <p>
                                        Please enter the code sent to your email address <strong>{userInfo?.email}</strong> to continue.
                                        If you haven't received the code, please check your spam folder or click the "Send/Resend code" button below.
                                        Note that the code is case-sensitive, so make sure to enter it exactly as it appears in the email.
                                    </p>
                                </InsetText>
                                
                                {TFAMessage && (
                                    <div style={{ 
                                        padding: "12px 16px", 
                                        margin: "16px 0", 
                                        background: "#f0f4f5", 
                                        borderRadius: "4px",
                                        borderLeft: TFAMessage.includes("✅") ? "4px solid #007f3b" : 
                                                   TFAMessage.includes("❌") ? "4px solid #d5281b" : 
                                                   "4px solid #005eb8"
                                    }}>
                                        <p style={{ margin: 0 }}>{TFAMessage}</p>
                                    </div>
                                )}

                                <form onSubmit={handle2FACodeSubmission} className="nhsuk-form-group">
                                    <div className="nhsuk-form-group">
                                        <label className="nhsuk-label" htmlFor="code">
                                            Verification Code
                                        </label>
                                        <input
                                            className="nhsuk-input"
                                            id="code"
                                            name="code"
                                            type="text"
                                            onChange={(e) => setCode(e.target.value)}
                                            value={code}
                                            required
                                            style={{ 
                                                borderRadius: "4px", 
                                                fontSize: "1.2rem",
                                                letterSpacing: "0.1em" 
                                            }}
                                        />
                                    </div>

                                    <button 
                                        className="nhsuk-button" 
                                        data-module="nhsuk-button" 
                                        type="submit" 
                                        style={{ 
                                            width: '100%', 
                                            marginTop: '24px',
                                            borderRadius: '4px',
                                            fontWeight: 'bold'
                                        }} 
                                        disabled={loading}
                                    >
                                        {loading ? 'Verifying...' : 'Verify Code'}
                                    </button>
                                </form>
                                
                                <div style={{ 
                                    display: "flex", 
                                    justifyContent: "space-between", 
                                    alignItems: "center",
                                    marginTop: "24px",
                                    borderTop: "1px solid #f0f4f5",
                                    paddingTop: "16px"
                                }}>
                                    <a 
                                        href="#" 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handle2FACodeRequest(email);
                                        }}
                                        className="nhsuk-link" 
                                        style={{ 
                                            pointerEvents: sendingCode || isBlocked ? "none" : "auto",
                                            opacity: sendingCode || isBlocked ? 0.7 : 1,
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {sendingCode ? 'Sending code...' : isBlocked ? 'Locked' : 'Send/Resend code'}
                                    </a>
                                    
                                    <Link 
                                        to="/" 
                                        onClick={handleLogout}
                                        className="nhsuk-link"
                                    >
                                        Cancel and Logout
                                    </Link>
                                </div>
                            </Card.Content>
                        </Card>

                        <p style={{ 
                            marginTop: "24px", 
                            fontSize: "0.9rem", 
                            textAlign: "center",
                            color: "#4c6272"
                        }}>
                            By using this service you are agreeing to our <a href="https://www.bartshealth.nhs.uk/privacy" className="nhsuk-link">terms of use</a> and <a href="https://www.bartshealth.nhs.uk/privacy" className="nhsuk-link">privacy policy</a>.
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default TwoFACodeRequestScreen;