import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ActionLink } from 'nhsuk-react-components';

function HomeScreen() {
  useEffect(() => {
    document.title = "MyHeartPassport - Home";
  }, []);

  return (
    <div className="nhsuk-width-container">
      <main className="nhsuk-main-wrapper" id="maincontent" role="main">
        {/* Hero Banner - similar to NHS.uk */}
        <div style={{ 
          background: "#005eb8", 
          color: "white", 
          padding: "32px 16px",
          marginBottom: "32px" 
        }}>
          <h1>MyHeartPassport</h1>
          <h2 style={{ 
            fontWeight: "normal", 
            marginTop: "16px",
            fontSize: "2rem"
          }}>
            Find information and manage your cardiac implant device
          </h2>
        </div>

        {/* Main content blocks - using NHS kit action links */}
        <div className="nhsuk-grid-row">
          <div className="nhsuk-grid-column-one-half">
            <div style={{ 
              background: "white", 
              padding: "24px", 
              marginBottom: "24px",
              border: "1px solid #d8dde0",
              borderRadius: "4px"
            }}>
              <h2 style={{ marginBottom: "16px" }}>First Time User</h2>
              <p>
                Create an account to access your cardiac device information, track health readings, and share data with healthcare providers.
              </p>
              <div className="nhsuk-action-link">
                <ActionLink href="/register">
                  Register for an account
                </ActionLink>
              </div>
            </div>
          </div>
          
          <div className="nhsuk-grid-column-one-half">
            <div style={{ 
              background: "white", 
              padding: "24px", 
              marginBottom: "24px",
              border: "1px solid #d8dde0",
              borderRadius: "4px"
            }}>
              <h2 style={{ marginBottom: "16px" }}>Already Registered</h2>
              <p>
                Access your existing account to view your cardiac device information and manage your health data.
              </p>
              <div className="nhsuk-action-link">
                <ActionLink href="/login">
                  Log in to your account
                </ActionLink>
              </div>
            </div>
          </div>
        </div>

        {/* Log device complications */}
        

        {/* Short description about MyHeartPassport */}
        <div className="nhsuk-grid-row">
          <div className="nhsuk-grid-column-full">
            <div style={{ 
              background: "#f0f4f5", 
              padding: "24px", 
              marginBottom: "32px",
              borderLeft: "4px solid #005eb8" 
            }}>
              <h3 style={{ marginBottom: "16px" }}>About MyHeartPassport</h3>
              <p>
              MyHeartPassport is a digital platform designed to enhance cardiac implant device management. We ensure seamless access to clinically 
                relevant information for both patients and healthcare providers, facilitating real-time data access, 
                interoperability, and automated decision support.
              </p>
            </div>
          </div>
        </div>
        
        {/* Footer Info */}
        <div className="nhsuk-grid-row" style={{ borderTop: "1px solid #d8dde0", paddingTop: "24px", marginTop: "32px" }}>
          <div className="nhsuk-grid-column-full">
            <p>
              By using this service you are agreeing to our{" "}
              <a href="https://www.bartshealth.nhs.uk/privacy" style={{ color: "#005eb8" }}>privacy policy</a>.
            </p>
            <p style={{ marginTop: "16px" }}>
              Need help? Visit our{" "}
              <a href="https://www.bartshealth.nhs.uk/pals" style={{ color: "#005eb8" }}>
                advice and support for patients and visitors
              </a>
              {" "}page.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default HomeScreen;